import React, { Component } from 'react';
import { Item } from 'semantic-ui-react';
import RadioButtons from '../../02_molecules/BlockValues/RadioButtons';
import Checkboxes from '../../02_molecules/BlockValues/Checkboxes';
import Inputs from '../../02_molecules/BlockValues/Inputs';
import Delivery from '../../02_molecules/BlockValues/Delivery';
import Veranda from '../../02_molecules/BlockValues/Veranda';
import Terrace from '../../02_molecules/BlockValues/Terrace';
import Porch from '../../02_molecules/BlockValues/Porch';
import Foundation from '../../02_molecules/BlockValues/Foundation';
import HomeInstallation from '../../02_molecules/BlockValues/HomeInstallation';
import './_styles.scss';
import Stairs from '../../02_molecules/BlockValues/Stairs';
import EquipmentRental from '../../02_molecules/BlockValues/EquipmentRental';
import CheckboxInput from '../../02_molecules/BlockValues/CheckboxInput';
import AdditionalBathroom from "../../02_molecules/BlockValues/AdditionalBathroom";
import CustomOptions from "../../02_molecules/BlockValues/CustomOptions";
import { connect } from 'react-redux';
import getData from "../../../utils/getData";
import RadioCheckboxes from "../../02_molecules/BlockValues/RadioCheckboxes";
import ArchitecturalProject from "../../02_molecules/BlockValues/ArchitecturalProject";
import JustInput from "../../02_molecules/BlockValues/JustInput";

const components = {
  checkboxes: Checkboxes,
  radio: RadioButtons,
  veranda: Veranda,
  terrace: Terrace,
  porch: Porch,
  foundation: Foundation,
  stairs: Stairs,
  delivery: Delivery,
  home_installation: HomeInstallation,
  equipment_rental: EquipmentRental,
  input: Inputs,
  just_input: JustInput,
  checkbox_input: CheckboxInput,
  add_bathroom: AdditionalBathroom,
  custom: CustomOptions,
  radio_checkboxes: RadioCheckboxes,
  architectural_project: ArchitecturalProject
};

class BlocksList extends Component {

  render() {
    const { houseId } = this.props.state;
    const data = getData();

    return (
      <div className="blocks-list">

        <Item.Group divided>
          {data.blocks.map((item) => {
            const Block = components[item.values.type];
            if (!Block) { throw new Error(`Unknown block type: ${item.values.type}`)}
            if (item.hasOwnProperty('available')) {
              if (!item.available.includes(houseId)) {
                return null;
              }
            }
            return <Block key={item.machine_name} block={item} />;
          })}
        </Item.Group>

      </div>
    );
  }
}

const mapStateToProps = ( state ) => ({
  state
});
export default connect(mapStateToProps)(BlocksList);
