import React, { Component } from 'react';
import { Checkbox } from 'semantic-ui-react';
import { connect } from 'react-redux';
import Tip from '../../../01_atoms/Tip';
import Price from '../../../01_atoms/Price';
import BlockContainer from '../../BlockContainer';
import { deleteBlockValue, updateBlockValue } from '../../../../store/actions';
import divideNumber from '../../../../utils/divideNumber';
import { translate } from '../../../../utils/translations';
import { checkboxInputPriceCalculation } from '../../../../utils/blocksPriceCalculation';
import getBlockImage from "../../../../utils/getBlockImage";
import {initialCheckboxInputBlock} from "../../../../utils/initialBlock";
import {inputFilter} from "../../../../utils/inputFilter";
import getCurrencySign from "../../../../utils/getCurrencySign";
import Input from "../../../01_atoms/Input";

class CheckboxInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lastChecked: undefined,
      currentImage: props.block.image,
    };

    const { blockPriceValues } = this.props;

    Object.keys(blockPriceValues).forEach(key =>{
      if (blockPriceValues[key].hasOwnProperty('value')) {
        let value = Number(blockPriceValues[key].value);
        value = value === 0 ? '' : value;
        this.state[key] = value;
      }
    });

    this.checkboxHandleChange = this.checkboxHandleChange.bind(this);
    this.inputHandleChange = this.inputHandleChange.bind(this);
    this.onMouseOver = this.onMouseOver.bind(this);
    this.onMouseOut = this.onMouseOut.bind(this);
  }

  componentWillUnmount() {
    const { block, dispatch } = this.props;
    dispatch(deleteBlockValue(block.machine_name));
  }

  componentDidMount() {
    const { block, dispatch, blockValue } = this.props;

    dispatch(updateBlockValue(block.machine_name, blockValue));
  }

  checkboxHandleChange(e, { value }) {
    const { dispatch, blockValue, block } = this.props;
    const newBlockValue = { ...blockValue };
    newBlockValue[value].checked = !newBlockValue[value].checked;
    dispatch(updateBlockValue(block.machine_name, newBlockValue));

    if (newBlockValue[value].checked) {
      this.setState({lastChecked: value})
    } else {
      this.setState({lastChecked: undefined})
    }
  }

  inputHandleChange(e) {
    const { value, name } = e.target;
    const { block, dispatch, blockValue } = this.props;

    let inputValue = inputFilter(value);

    if (
      block.machine_name === 'additional_options' &&
      name === '5'
    ) {
      inputValue = inputFilter(value, 'negative_numbers');
    }

    this.setState({[name]:inputValue});

    inputValue = inputValue === '-' ? '0' : inputValue;
    inputValue = inputValue === '' ? '0' : inputValue;
    const newBlockValue = Object.assign(blockValue);
    newBlockValue[name].value = inputValue;

    dispatch(updateBlockValue(block.machine_name, newBlockValue));
  }

  onMouseOver(e){
    const { block } = this.props;
    const optionIndex = e.target.previousSibling.value;
    const optionImage = getBlockImage(block, optionIndex);
    if (typeof optionImage !== "undefined") {
      this.setState({currentImage: optionImage});
    } else {
      const blockImage = getBlockImage(block);
      this.setState({currentImage: blockImage});
    }
  }

  onMouseOut(){
    const { block } = this.props;
    const lastChecked = this.state.lastChecked;
    if (typeof lastChecked !== "undefined") {
      const optionImage = getBlockImage(block, lastChecked);
      if (typeof optionImage !== "undefined"){
        this.setState({currentImage: optionImage});
      } else {
        const blockImage = getBlockImage(block);
        this.setState({currentImage: blockImage});
      }
    } else {
      const blockImage = getBlockImage(block);
      this.setState({currentImage: blockImage});
    }
  }

  render() {
    const { block, language, blockPriceValues } = this.props;
    const { options } = block.values;

    return (
      <BlockContainer
        image={this.state.currentImage}
        header={block.title}
        description={block.description}
        tooltip={block.tooltip}
      >
        {options.map((option) => {
          const index = option['machine_name'];
          let itemForRender;
          const activeClass = blockPriceValues[index].checked ? 'active': '';

          switch (option.type) {
            case 'checkbox':
              itemForRender = (
                <>
                  <Checkbox
                    label={translate(option.label, language)}
                    onChange={this.checkboxHandleChange}
                    onMouseOver = {this.onMouseOver}
                    onMouseOut = {this.onMouseOut}
                    value={index}
                    checked={blockPriceValues[index].checked}
                  />

                  {option.description && <Tip value={option.description} />}
                </>
              );
              break;

            case 'input':
              const inputValue = this.state[index];

              itemForRender = (
                <>
                  <Checkbox
                    label={translate(option.label, language)}
                    onChange={this.checkboxHandleChange}
                    onMouseOver = {this.onMouseOver}
                    onMouseOut = {this.onMouseOut}
                    value={index}
                    checked={blockPriceValues[index].checked}
                  />

                  {option.description && <Tip value={option.description} />}

                  <Input
                    label={`x ${divideNumber(blockPriceValues[index].unit_price)} ${getCurrencySign()}`}
                    onChange={this.inputHandleChange}
                    name={index}
                    labelPosition="right"
                    placeholder="0"
                    disabled={!blockPriceValues[index].checked}
                    value={inputValue}
                  />
                </>
              );
          }

          return (
            <div className={`option-container ${activeClass}`} key={option.label}>
              <div className='option'>
                {itemForRender}
              </div>

              <div className='option-price'>
                { Price(blockPriceValues[index].price.toString()) }
              </div>
            </div>
          );
        })}
      </BlockContainer>
    );
  }
}

const mapStateToProps = ({ blocks, houseId, region, language }, { block }) => {

  const blockValue = initialCheckboxInputBlock(block, blocks);

  const blockPriceValues = checkboxInputPriceCalculation(block, blockValue, { region, houseId });

  return {
    houseId,
    region,
    language,
    blockValue,
    blockPriceValues,
  };
};

export default connect(mapStateToProps)(CheckboxInput);
