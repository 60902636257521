import {
  checkboxesPDFObj,
  customPDFObj, foundationPDFObj,
  inputPDFObj,
  radioCheckboxesPDFObj,
  radioPDFObj,
  stairsPDFObj, verandaPDFObj
} from "./generatePDFObject";

export default function (block, blockValue, language) {
  const blockType = block.values.type;

  switch (blockType) {
    case 'radio': return radioPDFObj(block, blockValue, language);

    case 'delivery':
    case 'home_installation':
    case 'checkbox_input':
    case 'porch':
    case 'checkboxes': return checkboxesPDFObj(block, blockValue, language);

    case 'add_bathroom':
    case 'equipment_rental':
    case 'terrace':
    case 'input': return inputPDFObj(block, blockValue, language);

    case 'custom': return customPDFObj(block, blockValue, language);
    case 'stairs': return stairsPDFObj(block, blockValue, language);

    case 'architectural_project':
    case 'radio_checkboxes': return radioCheckboxesPDFObj(block, blockValue, language);

    case 'veranda': return verandaPDFObj(block, blockValue, language);
    case 'foundation': return foundationPDFObj(block, blockValue, language);

    default: throw new Error(`Block "${blockType}" cannot generate a PDF object`);
  }
}